<template>
  <v-card
    v-if="client"
    flat
    height="100%"
    color="white"
  >
    <v-toolbar
      flat
      class="sticky-toolbar-company"
    >
      <v-btn
        icon
        rounded
        @click.stop="$router.back()"
      >
        <v-icon>
          mdi-arrow-left
        </v-icon>
      </v-btn>
      <v-toolbar-title>{{ $t('profile|company_profile') }}</v-toolbar-title>
    </v-toolbar>
    <v-card-text class="mt-4">
      <v-text-field
        v-model="form.company_name"
        :label="$t('clients|company_name')"
        :readonly="client.realClient"
        dense
        outlined
      />
      <v-text-field
        v-model="form.company_type"
        :label="$t('clients|company_type')"
        :readonly="client.realClient"
        dense
        outlined
      />
      <v-text-field
        v-model="form.rcs_number"
        :label="$t('clients|registration_number')"
        :readonly="client.realClient"
        outlined
        dense
      />
      <v-text-field
        v-model="form.shared_capital"
        :label="$t('clients|share_capital')"
        :readonly="client.realClient"
        type="number"
        outlined
        dense
      >
        <v-menu
          slot="prepend-inner"
          size="18"
          class="ml-n3 mt-1"
        >
          <template v-slot:activator="{ on, attrs }">
            <div
              v-bind="attrs"
              v-on="on"
            >
              <div
                v-if="form.selected_currency"
                class="mr-2 mt-1"
              >
                {{ form.selected_currency.symbol }}
                <v-icon
                  size="18"
                  class="ml-n2"
                >
                  mdi-menu-down
                </v-icon>
              </div>
              <div
                v-else
                class="mr-2 mt-1"
              >
                {{ Currencies.EUR.symbol }}
                <v-icon
                  size="18"
                  class="ml-n2"
                >
                  mdi-menu-down
                </v-icon>
              </div>
            </div>
          </template>
          <v-list
            style="max-height: 400px; overflow-y: auto;"
          >
            <v-list-item
              v-for="(currency, index) in Currencies"
              :key="index"
              @click="form.selected_currency = currency"
            >
              <v-list-item-title>{{ currency.symbol }} - {{ currency.name }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-text-field>
      <v-text-field
        v-model="form.date_of_registration"
        :label="$t('clients|date_of_registration')"
        :readonly="client.realClient"
        outlined
        dense
      />
      <v-text-field
        v-model="form.place_of_registration"
        :label="$t('clients|company_registration_place')"
        :readonly="client.realClient"
        outlined
        dense
      />
      <v-text-field
        v-model="form.address"
        :label="$t('clients|address')"
        :readonly="client.realClient"
        outlined
        dense
      />
      <v-text-field
        v-model="form.city"
        :label="$t('clients|city')"
        :readonly="client.realClient"
        outlined
        dense
      />
      <v-text-field
        v-model="form.postal_code"
        :label="$t('clients|postal_code')"
        :readonly="client.realClient"
        outlined
        dense
      />
      <v-text-field
        v-model="form.email"
        :label="$t('common|email')"
        :readonly="client.realClient"
        outlined
        dense
      />
      <v-text-field
        v-model="form.company_representative_name"
        :label="$t('clients|company_representative')"
        :readonly="client.realClient"
        outlined
        dense
      />
      <v-text-field
        v-model="form.company_representative_email"
        :label="$t('clients|company_representative') + ' ' + $t('common|email')"
        :readonly="client.realClient"
        outlined
        dense
      />
    </v-card-text>
    <div
      style="position: sticky; bottom: 0px;"
    >
      <v-toolbar
        flat
        dense
        style="width: 100%"
      >
        <v-spacer />
        <v-btn
          v-if="!client.realClient"
          color="primary"
          class="mb-2"
          rounded
          dense
          small
          min-width="150"
          @click="submit({
            form: form,
            files: client.documents
          })"
        >
          {{ $t('actions|submit') }}
        </v-btn>
      </v-toolbar>
    </div>
  </v-card>
</template>

<script>
import ClientActions from '../../Clients/ClientMixins/ClientActions'
import clientMixin from '../../Clients/ClientMixins/clientMixin'
import Currencies from '@/enums/Currencies.json'

const formData = {
  company_name: null,
  company_registration: null,
  company_type: null,
  shared_capital: null,
  selected_currency: Currencies.EUR,
  rcs_number: null,
  legal_representative: null,
  email: null,
  address: null
}

export default {
  mixins: [ClientActions, clientMixin],
  data () {
    return {
      loading: false,
      error: null,
      form: { ...formData },
      Currencies,
      actualTab: null,
      actualClient: null
    }
  },
  mounted () {
    if (Object.keys(this.$route.params).length) {
      for (const key in this.$route.params) {
        if (Object.hasOwnProperty.call(this.$route.params, key)) {
          const obj = this.$route.params[key]
          if (key && key === 'client') {
            this.actualClient = obj
            this.form = obj.clientData
          }
        }
      }
    } else {
      this.actualClient = this.clients.find(c => c._id === this.$route.params._id) || this.companyClients.find(c => c._id === this.$route.params._id) || this.groupClients.find(c => c._id === this.$route.params._id)
      if (!this.actualClient) this.$router.push('/clients')
    }
  }
}
</script>

<style lang="scss" scoped>
  .general-form {
    position: relative;
    display: flex;
    flex-direction: column;
  }
  .sticky-toolbar-company {
    position: sticky;
    top: 48px;
    z-index: 1;
  }
</style>
